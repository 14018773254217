import React, { useEffect, useState } from "react"

import Container from "layout/Container"
import ProfileInfo from "./ProfileInfo"
import Layout from "layout/Layout"

import { getSignedInUser } from "../Auth/services/user"
import { isBrowser } from "../../services/general"

const Profile = (props) => {
  const [user, setUser] = useState({})
  const [userData, setUserData] = useState({})
  const [addresses, setAddresses] = useState([])

  let { pageContext } = props
  let { module } = pageContext

  useEffect(() => {
    if (isBrowser()) {
      let user = getSignedInUser()?.authUser
      let userData = getSignedInUser()?.userData
      let addressesList = getSignedInUser()?.addresses?.addresses || []

      setUser(user)
      setUserData(userData)
      setAddresses(addressesList)
    }
  }, [])

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
      isPrivate
      isPatient
    >
      <Container isCentered>
        <ProfileInfo
          user={user}
          userData={userData}
          addresses={addresses}
          setAddresses={setAddresses}
          location={props.location}
        />
      </Container>
    </Layout>
  )
}

export default Profile
